import { classNameJoin } from "utils/lib";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  frame?: boolean;
  ghost?: boolean;
}

const ExcelDownloadButton = ({
  small,
  frame,
  ghost,
  children,
  className,
  ...props
}: React.PropsWithChildren<IProps>) => {
  return (
    <button
      className={classNameJoin([
        "whitespace-nowrap select-none inline-block rounded-md border transition-all duration-300 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:border-secondary-50",
        small ? "h-[32px] px-3 text-sm" : "h-[40px] px-5",
        ghost
          ? "bg-white text-secondary-900 border-secondary-900"
          : frame
          ? "Emerald-emerald-300 text-emerald-500 bg-white"
          : "border-emerald-500 bg-emerald-500 text-white hover:bg-emerald-600",
        className,
      ])}
      {...props}
    >
      {children}
    </button>
  );
};

export default ExcelDownloadButton;
