import { IDateRange, ISearchWord } from "interfaces/service.interface";
import request, { TodoResponse } from "./config";

export const getPointCharging = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/points/payments/excel", { params });

export const getPointUsage = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/points/usage-history/excel", { params });

export const getSeller = (params: any) =>
  request.get<TodoResponse<any>>("/sellers/excel", { params });

export const getGuest = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/guests/excel", { params });

export const getBroadcastSales = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/broadcasts/sales/excel", { params });

export const getBroadcasts = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/broadcasts/excel", { params });

export const getMembershipSellers = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/memberships/sellers/excel", { params });

export const getReports = (params: ISearchWord & IDateRange) =>
  request.get<TodoResponse<any>>("/reports/excel", { params });
