import { ISortDirection } from "interfaces/service.interface";
import { classNameJoin } from "utils/lib";
import Icon from "../Icon";

interface IProps {
  sort: boolean;
  direction: ISortDirection;
}

/**
 * sortBys: {
 *  "key": "ASC" | "DESC"
 * }
 */

const SortArrow = ({ sort, direction }: IProps) => {
  if (!sort) return null;

  return (
    <div className="inline-flex flex-col items-center justify-center">
      <Icon
        className={classNameJoin([
          "-mb-[4px]",
          direction === "ASC" ? "text-black" : "text-secondary-600",
        ])}
        icon="caret-up"
        fontSize={12}
      />
      <Icon
        className={classNameJoin([
          "-mt-[4px]",
          direction === "DESC" ? "text-black" : "text-secondary-600",
        ])}
        icon="caret-down"
        fontSize={12}
      />
    </div>
  );
};

export default SortArrow;
