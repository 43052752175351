import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IParams {
  defaultPage?: number;
  defaultSize?: number;
}

const usePagination = ({ defaultPage = 1, defaultSize = 10 }: IParams) => {
  const [page, setPage] = useState<number>(defaultPage);
  const [size, setSize] = useState<number>(defaultSize);

  const location = useLocation();
  const navigate = useNavigate();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const changePage = useCallback(
    (p: number) => {
      setPage(p);
      params.set("page", p.toString());

      const search = params.toString();
      navigate({
        search,
      });
    },
    [navigate, params]
  );

  const changeSize = useCallback(
    (s: number) => {
      setSize(s);
      params.set("size", s.toString());

      const search = params.toString();
      navigate({
        search,
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    const p = params.get("page");
    if (p && /^[0-9]*$/.test(p)) {
      setPage(parseInt(p));
    }
  }, [params]);

  return { page, size, changePage, changeSize };
};

export default usePagination;
