import {
  ISalesHistoryStatus,
  TSalesProductStatus,
} from "interfaces/sales.interface";

export const dayOfweekKO = ["일", "월", "화", "수", "목", "금", "토"];

export const orderStatus: {
  [key in TSalesProductStatus]: string;
} = {
  COMPLETE: "구매완료",
  REQUEST: "구매요청",
  CANCEL: "구매취소",
};

export const salesStatus: { [key in ISalesHistoryStatus]: string } = {
  CANCEL: "결제취소",
  COMPLETION: "결제완료",
  REFUND: "환불",
  WAITING: "입금대기",
};
