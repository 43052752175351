import { classNameJoin } from "utils/lib";
import Progress from "./Progress";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  frame?: boolean;
  ghost?: boolean;
  loading?: boolean;
}

const Button = ({
  children,
  className,
  disabled,
  frame,
  ghost,
  loading,
  small,
  ...props
}: React.PropsWithChildren<IProps>) => {
  return (
    <button
      {...props}
      className={classNameJoin([
        "whitespace-nowrap select-none inline-block rounded-md border transition-all duration-300 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:border-secondary-50",
        small ? "h-[32px] px-3 text-sm" : "h-[40px] px-5",
        ghost
          ? "bg-white text-secondary-900 border-secondary-900"
          : frame
          ? "border-primary-900 text-primary-900 bg-white"
          : "border-primary-900 bg-primary-900 text-white hover:bg-primary-800",
        className,
      ])}
      disabled={disabled || loading}
    >
      {loading ? <Progress size={24} color="text-secondary-900" /> : children}
    </button>
  );
};

export default Button;
