import {
  ILoginData,
  ILoginToken,
  IRefreshToken,
} from "interfaces/auth.interface";
import request, { Response } from "./config";

export const postLogin = (data: ILoginData) =>
  request.post<Response<ILoginToken>>("/auth/login", data);

export const postAccessRefresh = (data: { refreshToken: string }) =>
  request.post<Response<IRefreshToken>>("/auth/refresh", data);
