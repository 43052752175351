import { Suspense, useCallback, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useConfirm } from "utils/helper";
import { isLoginState } from "store/user";
import { postAccessRefresh } from "api/auth.request";
import { setHeader } from "api/config";
import env from "config/env";
import Home from "pages/Home";
import { LoginPage, LogoutPage } from "./pages";

const Fallback = () => <div />;

function App() {
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = useCallback(async () => {
    const refreshToken = localStorage.getItem(env.REFRESH_TOKEN);

    if (refreshToken) {
      const { data } = await postAccessRefresh({ refreshToken });

      if (data.data) {
        const { type, accessToken } = data.data.payload;
        setHeader(`${type} ${accessToken}`);
        setIsLogin(true);

        if (data.data.payload.refreshToken) {
          localStorage.setItem(
            env.REFRESH_TOKEN,
            data.data.payload.refreshToken
          );
        }
      }
    } else {
      navigate("/login");
    }
  }, [setIsLogin]);

  const initialize = useCallback(async () => {
    try {
      await refresh();
    } catch (err) {
      confirm.ok(
        "인증정보가 만료되었습니다.<br/>로그인 화면으로 이동합니다.",
        function () {
          navigate("/login");
        }
      );
    }
  }, [confirm, refresh]);

  useEffect(() => {
    if (isLogin && location.pathname === "/") {
      navigate("/dashboard");
      return;
    }

    if (
      !isLogin &&
      location.pathname !== "/login" &&
      location.pathname !== "/logout"
    ) {
      initialize();
    }

    console.log("App Initialize");
  }, [isLogin, initialize, location, navigate]);

  return (
    <div className="App">
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
