import dayjs from "dayjs";
import { dayOfweekKO } from "./data";

/**
 * className 병합
 * @param classNames (string | undefined | null | boolean)[]
 * @returns string
 */
export const classNameJoin = (
  classNames: (string | undefined | null | boolean)[]
) => classNames.filter((className) => !!className).join(" ");

/**
 * 금액에 comma 찍기
 * @param num number | string
 * @returns string
 */
export const numbericComma = (num: number | string) =>
  (num || "0").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * hypen 붙여서 전화번호 만들기
 * @param phone string
 * @returns string
 */
export const phoneHypen = (phone: string) =>
  phone.replace(/([0-9]{3})([0-9]{4})([0-9]{4})/, "$1-$2-$3");

/**
 * 줄바꿈 \n > <br/>
 * @param content string;
 * @returns string
 */
export const nl2br = (content?: string | null) =>
  (content || "").replace(/\n/g, "<br/>");

export const uuid = () => {
  return "xxxx-xxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 3) | 8;
    return v.toString(16);
  });
};

export const zero = (value: number | string) => {
  const str = value.toString();
  if (str.length === 1) {
    return "0" + str;
  }

  return str;
};

/**
 * 깊은 복사
 * @param source [] | {}
 * @returns [] | {}
 */
export const clone = (source: any) => {
  const keys = Array.isArray(source) ? null : Object.keys(source);
  const result: any = !keys ? [] : {};
  const length = !keys ? source.length : keys.length;

  for (let i = 0; i < length; i++) {
    const index = !keys ? i : keys[i];
    if (source[index] !== null && typeof source[index] === "object") {
      result[index] = clone(source[index]);
    } else {
      result[index] = source[index];
    }
  }

  return result;
};

/**
 * object에 지정된 key가 들어있는지 검사
 * @param obj object
 * @param key string
 * @returns boolean
 */
export const hasOwnProperty = (obj: object, key: string) =>
  Object.prototype.hasOwnProperty.call(obj, key);

/**
 * daysFormat
 * @param date string
 * @param format string?
 * @return string ex) 1분 전
 */
const MINUTE = 1000 * 60;
const HOUR = 1000 * 60 * 60;
const DATE = 1000 * 60 * 60 * 24;
export const daysFormat = (
  date: string,
  format: string = "YYYY.MM.DD HH:mm:ss"
) => {
  const millisecond = dayjs(date).valueOf();
  const result = dayjs().subtract(millisecond, "millisecond");
  const datetime = result.valueOf();

  const subtractDate = Math.floor(datetime / DATE);

  if (subtractDate > 7) {
    return dayjs(date).format(format);
  }

  if (datetime > DATE) {
    return Math.floor(datetime / DATE) + "일 전";
  }

  if (datetime > HOUR) {
    return Math.floor(datetime / HOUR) + "시간 전";
  }

  if (datetime > MINUTE) {
    return Math.floor(datetime / MINUTE) + "분 전";
  }

  return Math.floor(datetime / 1000) + "초 전";
};

export const YMDdHm = (date: string) =>
  dayjs(date)
    .format("YYYY.MM.DD ({d}) HH:mm")
    .replace(
      /(\{[0-9]+\})/,
      (n) => dayOfweekKO[parseInt(n.replace(/{|}/g, ""))]
    );

/**
 * Whale 의 경우 Safari, Chrome 이 전부 뜸
 * 희귀도 우선 순위로 체크됨
 */
export const client = () => {
  const agent = window.navigator.userAgent;

  if (/(Whale)/gi.test(agent)) {
    return "Whale";
  }

  if (/(Safari)/gi.test(agent)) {
    return "Safari";
  }

  if (/(Chrome)/gi.test(agent)) {
    return "Chrome";
  }

  return "None";
};

export const os = () => {
  const agent = window.navigator.userAgent;

  if (/(Mac)/gi.test(agent)) {
    return "Mac";
  }

  if (/(Windows)/gi.test(agent)) {
    return "Windows";
  }

  return "None";
};
