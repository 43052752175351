import * as excelAPI from "api/excel.request";
import dayjs from "dayjs";
import * as Exceljs from "exceljs";
import FileSaver from "file-saver";

type TExcelKey =
  | "charging"
  | "usage"
  | "sales"
  | "broadcasts"
  | "seller"
  | "guest"
  | "membership-seller"
  | "reports";

const style: Partial<Exceljs.Style> = {
  font: { bold: true },
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFF3F3F3" },
    bgColor: { argb: "FFF3F3F3" },
  },
};

/**
  alignment: {
    horizontal: "left",
    vertical: "middle",
  }
 */

const useExcelDownload = (key: TExcelKey, name: string) => {
  const mapping: { [key in TExcelKey]: keyof typeof excelAPI } = {
    broadcasts: "getBroadcasts",
    sales: "getBroadcastSales",
    charging: "getPointCharging",
    usage: "getPointUsage",
    seller: "getSeller",
    guest: "getGuest",
    "membership-seller": "getMembershipSellers",
    reports: "getReports",
  };

  const toExcelFile = async (
    data: any[],
    columns: { [key: string]: string }
  ) => {
    const workbook = new Exceljs.Workbook();
    const worksheet = workbook.addWorksheet(name);
    const keys = Object.keys(columns);

    worksheet.getRow(1).values = [name];
    worksheet.getRow(1).getCell(1).style = style;
    worksheet.getRow(1).height = 24;
    worksheet.mergeCells(1, 1, 1, keys.length);

    worksheet.getRow(2).getCell(1).value =
      "다운로드 일자 " + dayjs().format("YYYY.MM.DD HH:mm:ss");
    worksheet.getRow(2).height = 20;
    worksheet.mergeCells(2, 1, 2, keys.length);

    worksheet.getRow(4).values = Object.values(columns);

    for (let col = 1; col <= keys.length; col++) {
      worksheet.getRow(4).getCell(col).style = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFF3F3F3" },
          bgColor: { argb: "FFF3F3F3" },
        },
      };
    }

    for (let i = 5; i < data.length + 5; i++) {
      for (let j = 1; j <= keys.length; j++) {
        const value = data[i - 5][keys[j - 1]] || "-";
        worksheet.getRow(i).getCell(j).value = value;
      }
    }

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), name + ".xlsx");
    } catch (err) {}
  };

  async function download<T = any>(
    parameters: { [key: string]: any },
    callback: (
      data: T[],
      fn: (data: any[], columns: { [key: string]: string }) => void
    ) => void
  ) {
    const { data } = await excelAPI[mapping[key]]({ ...parameters });
    if (!data.data) return -1;

    callback(data.data.items, toExcelFile);
  }

  return download;
};

export default useExcelDownload;
