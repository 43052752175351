import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import navs from "config/navs";
import { Icon } from "components/shared";
import { classNameJoin } from "utils/lib";

const GlobalNavigationBar = () => {
  const { pathname } = useLocation();
  const domain = pathname.split("/")[1];

  return (
    <>
      <GNB>
        <div className="h-full w-[230px] bg-white border-r border-secondary-100">
          {navs.map((nav, i) => {
            return (
              <Wrapper key={i}>
                {nav.map(({ path, basename, name, icon, children }) => {
                  return (
                    <li key={path}>
                      <NavLink
                        className={({ isActive }) =>
                          classNameJoin([
                            "flex items-center h-[48px] px-4",
                            isActive || path.indexOf(domain) !== -1
                              ? " text-primary-900 bg-primary-50 font-medium"
                              : "text-secondary-700",
                          ])
                        }
                        to={path}
                      >
                        <Icon className="w-6 text-center" icon={icon} />
                        <span className="ml-3">{name}</span>
                      </NavLink>

                      {domain === basename && children && (
                        <ul>
                          {children.map(({ path, name, active }) => (
                            <li key={path}>
                              <NavLink
                                className={({ isActive }) =>
                                  classNameJoin([
                                    "block pl-[52px] py-2 text-[15px]",
                                    isActive ||
                                    (active && pathname.match(active))
                                      ? " text-primary-900"
                                      : "text-secondary-700",
                                  ])
                                }
                                to={path}
                                key={path}
                              >
                                {name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </Wrapper>
            );
          })}
        </div>
      </GNB>
    </>
  );
};

const GNB = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const Wrapper = styled.ul`
  position: relative;

  & + & {
    margin-top: 24px;
    padding-top: 24px;

    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 80%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #bebebe; // text-secondary-400
    }
  }
`;

export default GlobalNavigationBar;
