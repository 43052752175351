import styled from "styled-components";
import { classNameJoin } from "utils/lib";
import Icon from "./Icon";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Checkbox = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<IProps>) => {
  return (
    <label
      className={classNameJoin([
        "inline-flex items-center select-none cursor-pointer",
        className,
      ])}
    >
      <Input type="checkbox" {...props} />
      <Box>
        <Icon icon="check" fontSize={12} />
      </Box>
      <Span>{children}</Span>
    </label>
  );
};

const Box = styled.div`
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  position: relative;

  svg {
    display: none;
    position: absolute;
    color: #4c1d95;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Span = styled.span`
  color: #8b8b8b;
  font-size: 14px;
  margin-left: 10px;
`;

const Input = styled.input`
  display: none;

  &:checked {
    & + ${Box} {
      border-color: #4c1d95;
      background-color: #f5f3ff;

      & svg {
        display: block;
      }

      & + span {
        color: #4c1d95;
      }
    }
  }

  &:disabled {
    & + ${Box} {
      border-color: #aeaeae;
      background-color: #eaeaea;

      & svg {
        color: #aeaeae;
      }

      & + span {
        color: #aeaeae;
      }
    }
  }
`;
export default Checkbox;
