import styled from "styled-components";

interface IProps {
  tableHeight: string | number;
  onMouseDown: (ev: React.MouseEvent<HTMLDivElement>) => void;
}

const ResizeLine = ({ tableHeight, onMouseDown }: IProps) => {
  return <Styles onMouseDown={onMouseDown} />;
};

const Styles = styled.div`
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  border-right: 2px solid transparent;
  border-color: #ffffff;

  &:hover {
    border-color: #dcdcdc;
  }

  &:active {
    border-color: #58738b;
  }
`;

export default ResizeLine;
