const error = {
  "000001": "접근 권한이 없습니다.",
  "000002": "인증 절차에 문제가 발생했습니다.", // 리프래시 토큰이 무효화 되었습니다.
  "000003": "인증 절차에 문제가 발생했습니다.", // 리프래시 토큰 형식이 잘못되었습니다.
  "000004": "인증이 만료되었습니다.", // 리프레시 토큰이 만료되었습니다.
  "000005": "금지된 자원입니다.", // 금지된 자원입니다.
  "300001": "이미 가입된 아이디 입니다",
  "300002": "로그인에 실패했습니다.",
  "300003": "시작일이 종료일보다 큽니다.",
  "300004": "방송을 찾을 수 없습니다.",
  "300005": "게시물을 찾을 수 없습니다.",
  "300006": "존재하지 않는 셀러입니다.",
  "300007": "결제정보를 찾을 수 없습니다.",
  "300008": "결제된 포인트보다 많이 환불할 수 없습니다.",
  "300009": "토스 결제정보를 찾을 수 없습니다.",
  "300010": "환불할 수 없는 상태입니다.",
  "300011": "포인트가 부족하여 환불할 수 없습니다.",
  "300012": "잘못된 카테고리 요청입니다.",
};

export default error;
