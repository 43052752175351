import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import styled from "styled-components";
import GlobalNavigationBar from "layouts/GlobalNavigationBar";
import GlobalHeader from "layouts/GlobalHeader";
import routes from ".";

const Fallback = () => <div />;

const Home = () => {
  const pages = useRoutes(routes);

  return (
    <>
      <GlobalHeader />
      <GlobalLayout>
        <GlobalNavigationBar />
        <Suspense fallback={<Fallback />}>{pages}</Suspense>
      </GlobalLayout>
    </>
  );
};

const GlobalLayout = styled.main`
  position: relative;
  height: calc(100% - 56px);
  overflow: hidden;
`;

export default Home;
