import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { classNameJoin } from "utils/lib";

interface IProps extends IStyleProps {
  items: { label: string; value: string | number }[];
  value: string | number | null;
  className?: string;
  placeholder?: string;
  onChange: (value: string | number) => void;
}

interface IStyleProps {
  small?: boolean;
}

const Selector = ({
  items,
  small,
  value,
  className,
  placeholder,
  onChange,
}: IProps) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const clickHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();

    if (toggle) {
      setToggle(false);
      return;
    }

    setToggle(true);
  };

  const hide = () => {
    setToggle(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", hide);

    return function cleanup() {
      document.removeEventListener("mousedown", hide);
    };
  }, []);

  const label = useMemo(
    () => items.find((props) => props.value === value),
    [items, value]
  );

  return (
    <div className={classNameJoin(["relative", className])}>
      <Wrapper className="px-4" small={small} onMouseDown={clickHandler}>
        <span
          className={classNameJoin([
            !value ? "text-[#9ca3af]" : "",
            small ? "text-sm" : "",
          ])}
        >
          {label ? label.label : placeholder ? placeholder : "없음"}
        </span>
        <span className={!toggle ? "rotate-180" : ""}>&#8963;</span>
      </Wrapper>
      <SelectorBox className={classNameJoin([toggle ? "active" : ""])}>
        {items.map((item) => (
          <Option
            key={item.value}
            isActive={item.value === value}
            onClick={() => onChange(item.value)}
          >
            {item.label}
          </Option>
        ))}
      </SelectorBox>
    </div>
  );
};

const Wrapper = styled.div<IStyleProps>`
  cursor: pointer;
  position: relative;
  height: ${(props) => (props.small ? 32 : 40)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  background-color: white;
  user-select: none;
`;

const SelectorBox = styled.ul`
  position: absolute;
  font-size: 15px;
  top: 100%;
  margin-top: 4px;
  z-index: 50;
  background-color: white;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(-5px);
  overflow-y: auto;
  max-height: 250px;

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(5px);
  }
`;

const Option = styled.li<{ isActive?: boolean }>`
  padding: 10px 14px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #f5f3ff;
      color: #4c1d95;
    `}
`;

export default Selector;
