import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import useConfirm from "./useConfirm";

const useBlocker = (blocker: (tx: any) => void, when = true) => {
  const { navigator } = useContext<any>(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

const usePrompt = (content: string, when = true) => {
  const confirm = useConfirm();

  const blocker = useCallback(
    (tx: any) => {
      confirm.show({
        title: "해당 페이지에서 나가시겠습니까?",
        content,
        options: {
          cancleText: "아니요",
          confirmText: "나가기",
        },
        onConfirm() {
          tx.retry();
        },
      });
    },
    [content, confirm]
  );

  useBlocker(blocker, when);
};

export default usePrompt;
