import React, { useEffect, useMemo, useRef, useState } from "react";
import { ISortDirection } from "interfaces/service.interface";
import { IField, Options } from "./table.interface";
import TBody from "./TBody";
import THead from "./THead";
import { classNameJoin } from "utils/lib";

interface IProps extends React.TableHTMLAttributes<HTMLTableElement> {
  fields: IField[];
  data?: { [key in string]: any }[] | null;
  scrollable?: boolean;
  options?: Options;
  loading?: boolean;
  sortBys?: { [key: string]: ISortDirection }; // useSortBys -> original
  rowClass?: (data: any) => string;
  onSort?: (column: string, direction: ISortDirection) => void;
  onRowClicked?: (
    data: any,
    index: number,
    ev: React.MouseEvent<HTMLTableRowElement>
  ) => void;
}

const createHeaders = (headers: string[]) => {
  return headers.map((item) => ({
    text: item,
    ref: useRef<HTMLTableHeaderCellElement>(null),
  }));
};

const Table = ({
  className,
  data,
  scrollable,
  fields,
  options,
  loading = false,
  sortBys,
  rowClass,
  onSort,
  onRowClicked,
  ...props
}: IProps) => {
  const [tableHeight, setTableHeight] = useState<string | number>("auto");
  const tableRef = useRef<HTMLTableElement>(null!);

  const sortHandler = (index: number, direction: ISortDirection) => {
    const { column } = fields[index];
    onSort && onSort(column, direction);
  };

  const rowClickHandler = (
    data: any,
    index: number,
    ev: React.MouseEvent<HTMLTableRowElement>
  ) => {
    onRowClicked && onRowClicked(data, index, ev);
  };

  const [width, align, name, column, render, sort, overflow] = useMemo(() => {
    const w = [],
      a = [],
      n = [],
      c = [],
      r = [],
      s = [],
      o = [];

    for (let i = 0; i < fields.length; i++) {
      const { width, align, column, name, render, sort, overflow } = fields[i];
      w.push(width || "*");
      a.push(align || "text-center");
      n.push(name);
      c.push(column);
      r.push(render);
      s.push(sort || false);
      o.push(overflow === undefined ? false : overflow);
    }

    return [w, a, n, c, r, s, o];
  }, [fields]);

  const columns = createHeaders(name);

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.offsetHeight);
    }
  }, []);

  return (
    <table
      className={classNameJoin([
        "border-collapse w-full",
        scrollable ? "" : "table-fixed",
        className,
      ])}
      ref={tableRef}
      {...props}
    >
      <THead
        tableRef={tableRef}
        align={align}
        column={column}
        columns={columns}
        sort={sort}
        sortBys={sortBys}
        tableHeight={tableHeight}
        width={width}
        onSort={sortHandler}
      />
      <TBody
        align={align}
        column={column}
        data={data}
        loading={loading}
        options={options}
        overflow={overflow}
        render={render}
        rowClass={rowClass}
        onRowClick={rowClickHandler}
      />
    </table>
  );
};

export default Table;
