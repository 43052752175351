import Placeholder from "../../assets/placeholder.png";

interface IProps {
  src?: string | null;
  size?: number;
}

const Thumbnail = ({ src, size = 36 }: IProps) => {
  return (
    <div
      style={{ width: size, height: size, borderRadius: size / 2.5 }}
      className="overflow-hidden border border-secondary-200"
    >
      <img
        className="object-cover object-center w-full h-full"
        src={src || Placeholder}
        alt="썸네일"
      />
    </div>
  );
};

export default Thumbnail;
