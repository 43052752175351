import { IFile } from "interfaces/service.interface";
import Item from "./Item";

interface IProps {
  files: (File | IFile)[];
  limit?: number;
  onDelete: (index: number) => void;
}

const List = ({
  children,
  files,
  limit = 10,
  onDelete,
}: React.PropsWithChildren<IProps>) => {
  return (
    <div className="flex items-center select-none space-x-1">
      <ul className="flex flex-wrap items-center space-x-1">
        {files.map((file, i) => {
          return <Item key={i} file={file} onDelete={() => onDelete(i)} />;
        })}
      </ul>
      {files.length < limit && (
        <div className="flex items-center">
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

export default List;
