import Button from "./Button";
import List from "./List";
import Item from "./Item";

const components = {
  Button,
  List,
  Item,
};

export default components;
