import { IFile } from "interfaces/service.interface";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { hasOwnProperty } from "utils/lib";
import Icon from "../Icon";

interface IProps {
  file: File | IFile;
  onDelete: () => void;
}

export const Item = ({ file, onDelete }: IProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (ev) => {
      if (imageRef.current && ev.target) {
        imageRef.current.src = ev.target.result as string;
      }
    };

    // 클라이언트에서 업로드한 이미지 파일
    if (file instanceof File) {
      reader.readAsDataURL(file);
    }

    // 서버에서 가져온 파일배치
    if (hasOwnProperty(file, "url")) {
      if (imageRef.current) {
        imageRef.current.src = (file as IFile).url;
      }
    }
  }, [file]);

  return (
    <Wrapper onClick={onDelete}>
      <img ref={imageRef} alt="" />
      <Icon className="icon text-white" icon={["far", "trash-alt"]} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 52px;
  height: 52px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  object-fit: contain;
  overflow: hidden;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 40;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.25s;
    opacity: 0;
    background-color: black;
  }

  &:hover {
    .icon {
      display: block;
    }

    &::after {
      opacity: 0.4;
    }
  }
`;

export default Item;
