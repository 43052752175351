import { ISortBys, ISortDirection } from "interfaces/service.interface";
import { useCallback, useMemo, useState } from "react";

export type State = ISortBys;
export type Action = (
  key: string,
  order: ISortDirection,
  isForce?: boolean
) => void;

const useSortBy = (
  defaultSort?: ISortBys
): {
  sortBys: State;
  setSortBys: Action;
  original: { [key: string]: ISortDirection };
} => {
  const [sortBys, setSortBys] = useState<State>(defaultSort || {});

  const sortByHandler = useCallback((key: string, order: ISortDirection) => {
    setSortBys({ [key]: order });
  }, []);

  const result = useMemo(() => {
    const base: { [key: string]: ISortDirection } = {};

    for (let key in sortBys) {
      const index = `sortBys[${key}]`;
      base[index] = sortBys[key];
    }

    return base;
  }, [sortBys]);

  return { sortBys: result, setSortBys: sortByHandler, original: sortBys };
};

export default useSortBy;
